const environment = {
  quadroMedidas: {
    baseApi: "https://orcamentoapi.go.senac.br/api/v1",
    baseUrlAuth: "https://authadm.sescgo.com.br/",
    endPointLogin: "connect/authorize",
    endPointLogout: "account/logout",
    endPointChangePassword: "account/changepassword",
    endPointRefreshToken: "auth/refresh-token",
    clientId: "g-orcamentaria-senac-go",
    responseType: "code",
    scope: "offline_access",
    routerNotAuth: ["/auth", "/auth/refresh-token", "/authorization"],
  },
  toast: {
    timer: 10000,
  },
};

export default environment;
